import React, { useEffect, useState } from "react";
import Menu from "./components/Menu";
import useGetInventoryHead from "./hooks/useGetInventoryHead";
import useDateFilter from "../../hooks/useDateFilter";
import useAuth from "../../hooks/useAuth";
import Conteo from "./components/Conteo";
import useGetArticles from "./hooks/useGetArticles";
import { fullDateFormat } from "../../utils/dateFormat/dateFormat";
import useToggles from "./hooks/useToggles";
import { axiosPrivate } from "../../api/axios";
import toast from "react-hot-toast";
import Recepcion from "./components/Recepcion";
import ModalDelete from "../Inventory/components/ModalDelete";
import ModalConfirmConteo from "./components/ModalConfirmConteo";
import ModalConfirmRecepcion from "./components/ModalConfirmRecepcion";
import { usersOnlyInventory } from "../../assets/usersAccess";

const Index = () => {
  const { auth } = useAuth();
  const businessId = auth?.isMultibusiness
    ? auth?.selectedBusinessOnlyId
    : auth?.business?.ID_NEGOCIO;
  const selectedBranch = auth?.selectedBranch;
  const getBranchSelectedName = auth.branches.find((branch) => {
    return branch.ID_SUCURSAL.toString() === auth.selectedBranch.toString();
  })?.NOMBRE_SUCURSAL;

  const onlyInventory = usersOnlyInventory.includes(auth?.username);

  const { date: rangeDate, HandleDateChange: HandleRageDateChange } =
    useDateFilter("", "");

  const currentDate = new Date();
  const opciones = {
    timeZone: 'America/Mexico_City',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };
  const formato = currentDate.toLocaleString('es-ES', opciones);

// Separamos la fecha y la hora
const [fechaStr] = formato.split(', ');

// Separamos los componentes de la fecha
const [dia, mes, año] = fechaStr.split('/');

// Reordenamos los componentes para obtener 'año/mes/día'
const fechaFormateada = `${año}-${mes}-${dia}`;

  const [date, setDate] = useState(fechaFormateada);
  const [refresh, setRefresh] = useState(false);
  
  const { data: headData, HandleSearch } = useGetInventoryHead({
    first_date: rangeDate.from,
    second_date: rangeDate.to,
  });

  const { toggles, HandleToggle, ResetToggles } = useToggles();

  const {
    modifiedData: modifiedDataArticles,
    HandleSearch: HandleSearchArticles,
    editIncomeQty,
    HandleRecepcionQty,
    ResetArticlesData,
  } = useGetArticles();

  function HandleDate(e, isManual) {
    if (isManual) {
      return setDate(e);
    }
    const { value } = e.target;
    return setDate(fullDateFormat(value));
  }

  async function HandleVisualizeEdit(currentHeadData, type) {
    const bodyData = await axiosPrivate(
      `business/${businessId}/branch/${selectedBranch}/inventory/head&body/firstdate/${currentHeadData.createdAt}/seconddate/${currentHeadData.createdAt}`
    ).then((res) => {
      return res.data;
    });

    editIncomeQty(bodyData[0]?.inventory_bodies, type);
    HandleDate(bodyData[0]?.createdAt, true);
    HandleToggle(type, true, true);
  }

  const [deleteInfo, setDeleteInfo] = useState({
    createdAt: "",
    type: "",
  });

  function HandleDelete({ date, type, typeTitle }) {
    return setDeleteInfo({
      date,
      type,
      typeTitle,
    });
  }

  async function postDelete() {
    try {
      const type = deleteInfo.type;
      const specificDate = deleteInfo.date;

      if (type === "conteo") {
        const inventoryHead = await axiosPrivate.put(
          `business/${businessId}/branch/${selectedBranch}/inventory/conteo/disable`,
          {
            date: specificDate,
          }
        );
        // await axiosPrivate.put(`business/inventory/delete/recepcion/head_id/:head_id`)
        toast.success("Registro eliminado con éxito!");
        HandleToggle("modal_delete", false);
      } else if (type === "recepcion") {
        await axiosPrivate
          .put(
            `business/${businessId}/branch/${selectedBranch}/inventory/recepcion/disable`,
            {
              date: specificDate,
            }
          )
          .then((res) => {
            if (res.status === 200) HandleToggle("modal_delete", false);
            toast.success("Registro eliminado con éxito!");
          });
      }

      setRefresh(!refresh);
    } catch (error) {
      console.error(error);
    }
  }

  async function createOrUpdateConteo() {
    try {
      const checkConteo = await axiosPrivate
        .get(
          `business/${businessId}/branch/${selectedBranch}/inventory/check/conteo/date/${date}`
        )
        .then((res) => res.data);

      if (checkConteo.exists && onlyInventory)
        return toast.error("Ya existe conteo para la fecha seleccionada.");

      if (checkConteo.exists && !toggles.modalConfirmConteo)
        return HandleToggle("modalConfirmConteo", true);

      const head = await axiosPrivate
        .post(
          `business/${businessId}/branch/${selectedBranch}/inventory/head/create`,
          {
            has_conteo: true,
            date: fullDateFormat(date),
          }
        )
        .then((res) => {
          return res.data;
        });

      modifiedDataArticles.forEach(async (articles) => {
        // if (articles.incomeQty > 0) {
        await axiosPrivate.post(
          `business/${businessId}/inventory/body/create`,
          {
            id_inventory_head: head.id,
            invennum: articles.invennum,
            cost: articles.costperu,
            conteo_cantidad: parseInt(articles.incomeQty),
            date,
          }
        );
      });
      ResetToggles();
      ResetArticlesData();
      setRefresh(!refresh);
      toast.success("Conteo creado con éxito");
    } catch (error) {
      toast.error("Error al crear el conteo");
      console.error(error);
    }
  }

  async function createOrUpdateRecepcion() {
    try {
      const checkRecepcion = await axiosPrivate
        .get(
          `business/${businessId}/branch/${selectedBranch}/inventory/check/recepcion/date/${date}`
        )
        .then((res) => res.data);

      if (checkRecepcion.exists && onlyInventory)
        return toast.error("Ya existe recepción para la fecha seleccionada.");

      if (checkRecepcion.exists && !toggles.modalConfirmRecepcion)
        return HandleToggle("modalConfirmRecepcion", true);

      const head = await axiosPrivate
        .post(
          `business/${businessId}/branch/${selectedBranch}/inventory/head/create`,
          {
            has_recepcion: true,
            date: fullDateFormat(date),
          }
        )
        .then((res) => {
          return res.data;
        });

      modifiedDataArticles.forEach(async (articles) => {
        // if (articles.incomeQty > 0) {
        await axiosPrivate.post(
          `business/${businessId}/inventory/body/create`,
          {
            id_inventory_head: head.id,
            invennum: articles.invennum,
            recepcion_cantidad: articles.incomeQty,
            date,
          }
        );
        // }
      });
      ResetToggles();
      ResetArticlesData();
      setRefresh(!refresh);
      toast.success("Recepción creado con éxito");
    } catch (error) {
      toast.error("Error al crear el recepción");
      console.error(error);
    }
  }

  useEffect(() => {
    HandleSearch();
    HandleSearchArticles();
  }, [auth, rangeDate, refresh]);

  return (
    <main className="inventory2-main">
      {toggles.modalConfirmConteo && (
        <ModalConfirmConteo
          HandleToggle={HandleToggle}
          handleSubmit={createOrUpdateConteo}
        />
      )}
      {toggles.modalConfirmRecepcion && (
        <ModalConfirmRecepcion
          HandleToggle={HandleToggle}
          handleSubmit={createOrUpdateRecepcion}
        />
      )}
      {!toggles.conteo && !toggles.recepcion && (
        <Menu
          onlyInventory={onlyInventory}
          toggles={toggles}
          headData={headData}
          rangeDate={rangeDate}
          HandleRageDateChange={HandleRageDateChange}
          HandleToggle={HandleToggle}
          HandleDelete={HandleDelete}
          HandleVisualizeEdit={HandleVisualizeEdit}
        />
      )}
      {toggles.modal_delete && (
        <ModalDelete
          postDelete={postDelete}
          deleteInfo={deleteInfo}
          HandleDelete={HandleDelete}
          HandleToggle={HandleToggle}
        />
      )}
      {toggles.conteo && (
        <Conteo
          toggles={toggles}
          date={date}
          HandleDate={HandleDate}
          data={modifiedDataArticles}
          ResetArticlesData={ResetArticlesData}
          createOrUpdateConteo={createOrUpdateConteo}
          getBranchSelectedName={getBranchSelectedName}
          HandleToggle={HandleToggle}
          HandleRecepcionQty={HandleRecepcionQty}
        />
      )}
      {toggles.recepcion && (
        <Recepcion
          toggles={toggles}
          date={date}
          HandleDate={HandleDate}
          data={modifiedDataArticles}
          createOrUpdateRecepcion={createOrUpdateRecepcion}
          getBranchSelectedName={getBranchSelectedName}
          HandleToggle={HandleToggle}
          HandleRecepcionQty={HandleRecepcionQty}
          ResetArticlesData={ResetArticlesData}
        />
      )}
    </main>
  );
};

export default Index;
